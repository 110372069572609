import React, {useState} from "react";
import { Input, Spin } from 'antd';
import {URL_API_GPT_API} from "../../config";
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageToolGPT = () => {
    const { Search } = Input;
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleSearch = async (value) => {
        if(value !== ""){
            setLoading(true);
            setImages([]);
            await axios.post(
                `${URL_API_GPT_API}/create_image`,
                {
                    "text": value
                }
            ).then((res) => {
                setImages(res.data);
            }).catch((error) => {
                console.log(error);
            });
            setLoading(false);
        }
    }
  return (
      <div>
          <Search
              className={"gpt-input-search"}
              disabled={loading}
              placeholder="Enter the content to be processed."
              onSearch={handleSearch}
              style={{ width: "100%", height: "40px", fontSize: "20px" }}
              loading={loading}
              enterButton={"Submit"}
          />

          {images.length >= 1 &&
              <Spin spinning={loading} tip="Please wait...">
              <div style={{
                  display: "flex",
                  padding: 15,
                  background: "#ffffff",
                  marginTop: 20,
                  border: "1px solid #d9d9d9",
                  borderRadius: 5,
                  minHeight: 150,
                  fontSize: 16,
                  color: "#353535",
                  maxHeight: "65vh",
                  overflow: "auto",
                  justifyContent: "space-between"
              }}>
                  <div style={{
                      width: "49%",
                  }}>
                      <LazyLoadImage
                          height={"100%"}
                          src={images}
                          width={"100%"}
                          effect={"opacity"}
                      />
                  </div>
              </div>
              </Spin>
          }
      </div>
  )
}

export default ImageToolGPT;