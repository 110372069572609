const origin = window.location.origin
export const MODE = 'dev'
// export const API_URL = (MODE === 'dev' && origin === 'http://localhost:8888') ? 'http://localhost:8888' : 'http://localhost:8888'
// export const APP_URL = (MODE === 'dev' && origin === 'http://localhost:3000') ? 'http://localhost:3000' : 'http://localhost:3000'
export const API_URL = 'https://v2-api.oopprint.com'
export const APP_URL = 'https://v2.oopprint.com'
export const SHOPIFY_API = 'a6c41424d7f53a4c5aa9ba7eb86e8835'
export const SHOPBASE_API = 'xxx'
export const CLOUDFRONT_URL = 'https://d17gmq2kqyh5z9.cloudfront.net'
export const PAYPAL_ENV = "sandbox"//(MODE === 'dev' && origin === 'http://localhost:3000') ? 'sandbox' : 'production'; // you can set here to 'production' for production (sandbox)
export const CURRENCY = "USD"; // or you can set this value from your props or state
// Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/
export const PAYPAL_SANDBOX_KEY = "xxx"
export const PAYPAL_PRODUCTION_KEY = "xxx"
export const CLOUDFRONT_URL_CUSTOM = 'https://d7re1xv4rs2gf.cloudfront.net'
export const URL_API_GPT_API = "https://7k4jmgh6umcroz3a2mf6p2u7xu0ezcwl.lambda-url.ap-southeast-1.on.aws"
export const URL_API_GET_BRAND_NAME = "https://api.oopprint.com/clone/amz-brandName"
export const URL_API_CLONE_PRODUCT_TO_V1 = "https://v2-api.oopprint.com/clone/clone-product-toV1"
export const ACCESS_KEY_PRINT_FILE_PREVIEW = "jlavnv202492E78E4B31357Fu7rVsYJfS";